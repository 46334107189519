<!--
 * @Description: 
 * @Author: xiaoq
 * @Date: 2024-04-03 15:43:02
 * @LastEditTime: 2024-12-12 16:01:29
 * @LastEditors: xiaoq
-->
<template>
  <footer class="footer min-w-1400 max-w-1400">
    <div class="footer-content">
      <!-- <p class="firstLine">
        ควบคุมโดยสำนักงานคณะกรรมการการธนาคารแห่งรัฐแห่งประเทศไทยและสำนักงานคณะกรรมการกำกับหลักทรัพย์
      </p> -->
      <p class="secondLine">Copyright ©Generando RLQ, S.A. de C.V., SOFOM, E.N.R.</p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #004751 ;
  color: white;
  /* height: 160px; */
  height: 70px;
  flex-direction: column;
}

.footer-content {
  font-size: 22px;
  text-align: center;
  margin: 0 auto;
  font-family: Poppins-Regular;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}
.firstLine {
  width: 1270px;
  height: 20px;
  font-size: 24px;
  font-family: Poppins-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}
.secondLine {
  width: 1098px;
  height: 20px;
  font-size: 14px;
  font-family: Poppins-Regular;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  opacity: 0.6;
}
</style>
